<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ name }}</p>
            <span class="user-status">{{ role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <b-link
            :to="{
              path: '/account-setting',
            }"
          >
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
          </b-link>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item @click="logoutdata"  link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span >Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import axios from "axios";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    toggleVerticalMenuActive(val) {
      var roledata = localStorage.getItem("Datauser");
      // console.log("DatauserDatauser", roledata);

      this.name = roledata.users.name;
      this.role = localStorage.getItem("permission");
    },
  },
  data() {
    return {
      role: "",
      name: "",
      avatar: "",
    };
  },

  mounted() {
    //  console.log("localhost",localStorage.getItem("permission"))
    if (localStorage.getItem("permission")) {
      var permission = JSON.parse(localStorage.getItem("permission"));
      this.role = permission.roleName;
    }
    if (localStorage.getItem("Datauser")) {
      var user_name = JSON.parse(localStorage.getItem("Datauser"));
      this.name = user_name.user.name;
    }
    if (localStorage.getItem("Datauser")) {
      var user_name = JSON.parse(localStorage.getItem("Datauser"));
      if (user_name.user.avatar) {
        this.avatar = user_name.user.avatar;
      }
    }
    // console.log("localhost",permission.roleName );
  },
  methods: {
    // getuserdata: function () {
    // alert(0);
    // axios
    //   .get(process.env.VUE_APP_API_URL + "/getuser", {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + localStorage.getItem("_t"),
    //     },
    //   })
    //   .then((get_response) => {
    //     console.log(get_response.data);
    //     // this.table_data = get_response.data;
    //     // console.log(this.table_data);
    //   });
    // },

    logoutdata() {
      axios
        .get(process.env.VUE_APP_API_URL + "/user/logout", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          if (get_response.data == "User is logout") {
            localStorage.removeItem("_t");
            localStorage.removeItem("className");
            localStorage.removeItem("subjectName");
            localStorage.removeItem("categoryName");
            localStorage.removeItem("categoryId");
            localStorage.removeItem("Datauser");
            localStorage.removeItem("demo_table_data");
            localStorage.removeItem("selectSubject");
            localStorage.removeItem("feedbackQuestionId");
            localStorage.removeItem("userData");
            localStorage.removeItem("roles");
            localStorage.removeItem("permission", { permission: [] });
            window.location = "/#/login";
          }
        });

      // window.close();
    },
  },
};
</script>
